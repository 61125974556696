var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  }, [_c('div', {
    staticClass: "p-4 flex-auto"
  }, [_c('div', {
    staticClass: "relative h-auto"
  }, [_c('canvas', {
    staticStyle: {
      "height": "450px"
    },
    attrs: {
      "id": "line-chart"
    }
  })])])]);

}]

export { render, staticRenderFns }